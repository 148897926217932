import * as React from 'react';

import { ICoreContext } from '@msdyn365-commerce/core';
import { getPayloadObject, getTelemetryAttributes, ITelemetryContent, onTelemetryClick } from '@msdyn365-commerce-modules/utilities';

interface ILink {
    text?: string;
    context: ICoreContext;
    href?: string;
    ariaLabel?: string;
    index: string;
    noclick?: boolean;
    telemetryContent: ITelemetryContent;
}

/**
 *
 * EcommContentTemplate1View component
 * @extends {React.PureComponent<ILink>}
 */
 export class Link extends React.PureComponent<ILink> {
    public render(): JSX.Element | null {
        const payLoad = getPayloadObject('click', this.props.telemetryContent, '', '');
        const linkText = this.props.text !== undefined ? this.props.text : '';

        payLoad.contentAction.etext = linkText;

        // Construct telemetry attribute to render.
        const attribute = getTelemetryAttributes(this.props.telemetryContent, payLoad);

        return (
            <a
                {...attribute}
                href={this.props.href}
                aria-label={this.props.ariaLabel}
                onClick={onTelemetryClick(this.props.telemetryContent, payLoad, linkText)}
                {...(this.props.noclick && { 'data-no-click': true })}
            >
                <span itemProp='name'>{linkText}</span>
                <meta itemProp='position' content={this.props.index} />
            </a>
        );
    }
 }

export default Link;